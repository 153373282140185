var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{attrs:{"label-position":"right"}},[_c('title-and-description',{attrs:{"field":_vm.field}})],1),_c('div',[_c('div',{staticClass:"main-form mt-1 scrollbar-content d-flex",staticStyle:{"gap":"10px"}},[_c('div',{staticClass:"d-flex-col"},[_c('label',[_vm._v("Entities")]),_c('el-select',{staticClass:"mb-1",attrs:{"no-data-text":"No Entities available","filterable":"","default-first-option":""},on:{"change":_vm.getEntityDateFields},model:{value:(_vm.addStatsData.entity_id),callback:function ($$v) {_vm.$set(_vm.addStatsData, "entity_id", $$v)},expression:"addStatsData.entity_id"}},_vm._l((_vm.getAllEntitiesData),function(entity,index){return _c('el-option',{key:index,attrs:{"value":entity._id,"label":entity.name,"disabled":_vm.checkSelectedEntity(entity)}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(entity.name))])])}),1),(_vm.addStatsData && _vm.addStatsData.entity_id)?_c('EntityFilters',{attrs:{"field":_vm.addStatsData,"fieldsData":_vm.fieldsData}}):_vm._e()],1),_c('div',{staticClass:"d-flex-col"},[_c('label',[_vm._v("Fields")]),_c('el-select',{staticClass:"mb-1",attrs:{"filterable":"","clearable":"","default-first-option":"","multiple":"","collapse-tags":""},model:{value:(_vm.addStatsData.field),callback:function ($$v) {_vm.$set(_vm.addStatsData, "field", $$v)},expression:"addStatsData.field"}},_vm._l((_vm.addStatsData.entity_id
              ? _vm.getTemplateDateFields
              : []),function(fl,index){return _c('el-option',{key:fl.key + index,attrs:{"value":fl.key,"label":`${fl.entity_name} - ${fl.template_name} - ${fl.label} `,"disabled":(_vm.field && _vm.field.selectedEntities
                ? _vm.field.selectedEntities
                : []
              ).findIndex((ex) => ex.field == fl.key) != -1}},[_c('span',[_vm._v(_vm._s(fl.entity_name)+" - "+_vm._s(fl.template_name)+" - "+_vm._s(fl.label))])])}),1)],1),_c('div',[_c('el-button',{staticClass:"mt-1",attrs:{"type":"primary","icon":"el-icon-plus","disabled":_vm.checkNewDate},on:{"click":_vm.addNewDate}},[_vm._v("Add")])],1)]),_c('div',[_c('el-table',{attrs:{"data":_vm.field.selectedEntities,"border":""}},[_c('el-table-column',{attrs:{"prop":"Contact Type","label":"Fields"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getFieldLabel(scope.row.field))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"Custom Message","label":"Custom Message","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.field &&
                scope.row.field.includes('#') &&
                scope.row.field.split('#')[0]
              )?_c('div',[_c('CustomEntityMention',{attrs:{"entity_id":scope.row.field.split('#')[0],"data":scope.row,"isFromApplicationuser":false}})],1):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"show every year","label":"Show every year"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"d-flex"},[_c('div',[_c('el-checkbox',{staticClass:"mr-2",attrs:{"type":"textarea"},model:{value:(scope.row.show_every_year),callback:function ($$v) {_vm.$set(scope.row, "show_every_year", $$v)},expression:"scope.row.show_every_year"}}),_c('el-link',{staticClass:"mr-2",attrs:{"type":"danger","icon":"el-icon-delete"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.deleteRow(scope.$index, _vm.field.selectedEntities)}}})],1),_c('div',[_c('el-color-picker',{attrs:{"title":"colorPicker","size":"mini"},model:{value:(scope.row.color),callback:function ($$v) {_vm.$set(scope.row, "color", $$v)},expression:"scope.row.color"}})],1)])]}}])}),_c('el-table-column',{attrs:{"label":"Show background color"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-checkbox',{attrs:{"disabled":_vm.checkBackgroundColor(scope.$index),"type":"textarea"},model:{value:(scope.row.applyBackground),callback:function ($$v) {_vm.$set(scope.row, "applyBackground", $$v)},expression:"scope.row.applyBackground"}})],1)]}}])})],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }